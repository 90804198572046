<template>
  <Tabs>
    <Tab title="totaal">
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Naam</th>
            <th class="table__head--text table__data--right">Sales</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(stagedays, key) in stagedays"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ key }}</td>
            <td class="table__cell--default table__data--right">
              {{ stagedays.amount_of_sales }}
            </td>
          </tr>
        </tbody>
        <tfoot class="table__foot">
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding table__data--right">{{ stagedays_total }}</th>
        </tfoot>
      </table>
    </Tab>
    <Tab v-for="(items, key) in reserved" :key="key" :title="`${key}`">
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Foto</th>
            <th class="table__head--text">Item</th>
            <th class="table__head--text">Datum</th>
            <th class="table__head--text table__data--right">status</th>
            <th
              v-if="table_kind == 'inkoper'"
              class="table__head--text table__data--right"
            >
              verkoper
            </th>
            <th v-else class="table__head--text table__data--right">Inkoper</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(vehicle, key) in items"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">
              <img
                :src="
                  `https://apps.kleyn.com:1919/prod/public/data/mainimage/${vehicle.nummer}/thumb`
                "
                class="table__picture--maxwidth"
              />
            </td>
            <td class="table__cell--default">
              <div>
                <ImageHover :itemnummer="vehicle.nummer" :bu="vehicle.bu" />
                {{ vehicle.soortnaam }}<br />
                {{ vehicle.opbouwnaam }}<br />
                {{ vehicle.merktype }}<br />
                {{ vehicle.regjaar }}
              </div>
            </td>
            <td class="table__cell--default">{{ vehicle.datum }}</td>
            <td class="table__cell--default table__data--right">
              {{ vehicle.status }}
            </td>
            <td
              v-if="table_kind == 'inkoper'"
              class="table__cell--default table__data--right"
            >
              {{ vehicle.verkopernaam }}
            </td>
            <td v-else class="table__cell--default table__data--right">
              {{ vehicle.inkopernaam }}
            </td>
          </tr>
        </tbody>
      </table>
    </Tab>
  </Tabs>
</template>
<script>
import ImageHover from "@/components/ImageHover.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: {
    stagedays: Object,
    reserved: Object,
    stagedays_total: Number,
    table_style: String,
    table_kind: String,
  },
  components: { ImageHover, Tabs, Tab },
};
</script>
