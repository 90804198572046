<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
      <Tabs
        v-if="!loading"
      >
        <Tab title="Inkopers">
          <div class="d-flex flex-row">
            <div class="flex-grow-1 p-3">
              <ReservedTable
                :reserved="reserved_per_buyer"
                :stagedays="stagedays_per_buyer"
                :stagedays_total="stagedays_per_buyer_total"
                :table_style="`${bu}`"
                table_kind="inkoper"
              />
            </div>
          </div>
        </Tab>
        <Tab title="Verkopers">
          <ReservedTable
            :reserved="reserved_per_seller"
            :stagedays="stagedays_per_seller"
            :stagedays_total="stagedays_per_seller_total"
            :table_style="`${bu}`"
            :table_kind="`verkoper`"
          />
        </Tab>
      </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import ReservedTable from "@/components/ReservedTable.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Loading, ReservedTable, Tabs, Tab },
  data: () => ({
    loading: true,
    reserved_per_buyer: null,
    stagedays_per_buyer: null,
    reserved_per_seller: null,
    stagedays_per_seller: null,
    stagedays_per_buyer_total: null,
    stagedays_per_seller_total: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`reserved-per-buyer/${bu}`, "GET").then(
        ({ reserved_per_buyer, stagedays_per_buyer, stagedays_per_buyer_total }) => {
          this.reserved_per_buyer = reserved_per_buyer;
          this.stagedays_per_buyer = stagedays_per_buyer;
          this.stagedays_per_buyer_total = stagedays_per_buyer_total;
        }
      );

      request(`reserved-per-seller/${bu}`, "GET").then(
        ({ reserved_per_seller, stagedays_per_seller, stagedays_per_seller_total }) => {
          this.reserved_per_seller = reserved_per_seller;
          this.stagedays_per_seller = stagedays_per_seller;
          this.stagedays_per_seller_total = stagedays_per_seller_total;
          this.loading = false;
        }
      );
    },
  },
};
</script>
